
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class StockDetailStatus extends Cell {
  stock = null

  async mounted () {
    const { item } = this

    const details = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_deal: { _eq: item.deal.id } },
      force: true,
    }))[0]

    this.stock = details?.stock
  }

  get status () {
    const { stock } = this

    return stock?.status
  }
  }
